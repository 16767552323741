import React from 'react';

import VideoCard from './components/VideoCard';

import {
  VideoCardGroupContainer,
  VideoCardList,
  Title,
  ExtraLink,
} from './styles';

interface Video {
  titulo: string;
  url: string;
}

interface LinkExtra {
  text: string;
  url: string;
}

interface Category {
  titulo: string;
  cor: string;
  link?: string;
  link_extra?: LinkExtra;
  videos: Video[];
}

interface VideoCardGroupProps {
  ignoreFirstVideo?: boolean;
  category: Category;
}

const VideoCardGroup: React.FC<VideoCardGroupProps> = ({
  ignoreFirstVideo,
  category,
}) => {
  const categoryTitle = category.titulo;
  const categoryColor = category.cor;
  const categoryExtraLink = category.link_extra;
  const { videos } = category;
  return (
    <VideoCardGroupContainer>
      {categoryTitle && (
        <>
          <Title style={{ backgroundColor: categoryColor || 'red' }}>
            {categoryTitle}
          </Title>
          {categoryExtraLink && (
            <ExtraLink href={categoryExtraLink.url} target="_blank">
              {categoryExtraLink.text}
            </ExtraLink>
          )}
        </>
      )}
      <VideoCardList>
        {videos.map((video, index) => {
          if (ignoreFirstVideo && index === 0) {
            return null;
          }

          return (
            <li key={video.titulo}>
              <VideoCard
                videoTitle={video.titulo}
                videoURL={video.url}
                categoryColor={categoryColor}
              />
            </li>
          );
        })}
      </VideoCardList>
    </VideoCardGroupContainer>
  );
};

export default VideoCardGroup;

// ###
// import React from 'react';

// // import {
// //   VideoCardGroupContainer,
// //   VideoCardList,
// //   Title,
// //   ExtraLink,
// // } from './styles';

// interface Video {
//   titulo: string;
//   url: string;
// }

// interface LinkExtra {
//   text: string;
//   url: string;
// }

// interface Category {
//   titulo: string;
//   cor: string;
//   link_extra: LinkExtra;
//   videos: Video[];
// }

// interface VideoCardGroupProps {
//   ignoreFirstVideo?: boolean;
//   category: Category;
// }

// const VideoCardGroup: React.FC = () => {
//   return <div>Componente tmpo</div>;
// };

// export default VideoCardGroup;
